import 'es6-promise/auto';
import { getFirstConsistentlyInteractive } from 'tti-polyfill';
import { getCLS, getFID, getLCP } from 'web-vitals';
import { error } from '@exp/exp-utils/helper/logger';

const vitals = {
  nav_type: 'hard'
};

let customProperties = {};

function usrinToString(usrin) {
  const resolvedString = Object.keys(usrin).map(key => [key, usrin[key]]).reduce((acc, curr, index) => {
    if (index > 0) {
      acc += '^';
    }
    return `${acc}${curr[0]},${curr[1]}`;
  }, '');
  return resolvedString.length ? resolvedString : void 0;
}

const _sendWebVitalsData = () => {
  if (vitals.timeToInteractive > 0) {
    if (window._tccInternal) {
      window._expDataLayer = window._expDataLayer || [];
      setTimeout(() => {
        window._expDataLayer.push({
          schema: 'add_perf',
          version: 'v1',
          data: {
            type: 'pageperf',
            properties: vitals,
            custom_properties: customProperties
          }
        });
      }, 0);
    } else {
      window._trfq = window._trfq || [];
      const convertedCustomProperties = usrinToString(customProperties);
      const composedVitals = Object.assign({}, vitals, convertedCustomProperties ? { usrin: convertedCustomProperties } : {});
      setTimeout(() => window._trfq.push(['cmdLogPerf', composedVitals]), 0);
    }
  }
};

const _collectVitals = ({ name, value } = {}) => {
  vitals[name] = (name === 'CLS') ? value : Math.round(value);    // Note: null value will round to 0
  if (name === 'timeToInteractive') {
    // First check if FID is present
    if (vitals.hasOwnProperty('FID')) {
      _sendWebVitalsData();
    } else {
      // Wait for FID ...
      let timesRun = 0;
      let waitForFID = setInterval(() => {
        if (vitals.hasOwnProperty('FID') || timesRun > 10) {
          if (waitForFID) {
            clearInterval(waitForFID);
            waitForFID = null;
          }
          _sendWebVitalsData();
        }
        timesRun += 1;
      }, 500);
    }
  }
};

const calculateTTI = (cb = _collectVitals) => {
  try {
    getLCP(cb, true);
    getFID(cb);
    getCLS(cb, true);
    getFirstConsistentlyInteractive().then((tti) => {
      cb({
        name: 'timeToInteractive',
        value: tti
      });
    });
  } catch (e) {
    error('unable to calculate "web-vitals" performance metrics', e);
  }
};

const setCustomProperties = (params = {}) => {
  customProperties = Object.assign({}, customProperties, params);
  return customProperties;
};

export { calculateTTI };
export { _collectVitals };
export { _sendWebVitalsData };
export { setCustomProperties };
